import React from 'react';
import { Typography, CardContent, CardMedia, IconButton, Tooltip } from '@mui/material';
import { Link as LinkIcon, GitHub, Description } from '@mui/icons-material'; // Import necessary icons
import { styled } from '@mui/system';

const HoverCard = styled('div')(({ theme }) => ({
  position: 'relative',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[5],
  },
  zIndex: 1000,
  paddingTop: 20,
  margin: 5,
  marginTop: 20,
  backgroundColor: theme.palette.highlight.main ,
  borderRadius: 16,
  height: '100%',
}));

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const IconButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', // Change to column layout
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

function ProjectCard({ title, description, image = false, link = false, github = false, file = false }) {
  return (
    <HoverCard>
      {(link || github || file) && (
        <IconButtonContainer>
          {link && (
            <Tooltip title="Learn More" placement="left">
              <IconButtonWrapper
                component="a"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon />
              </IconButtonWrapper>
            </Tooltip>
          )}
          {github && (
            <Tooltip title="GitHub" placement="left">
              <IconButtonWrapper
                component="a"
                href={github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHub />
              </IconButtonWrapper>
            </Tooltip>
          )}
          {file && (
            <Tooltip title="Article" placement="left">
              <IconButtonWrapper
                component="a"
                href={file}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Description />
              </IconButtonWrapper>
            </Tooltip>
          )}
        </IconButtonContainer>
      )}
      {image && (
        <CardMedia
          component="img"
          image={image}
          alt={`${title} Image`}
          style={{
            maxHeight: '140px',
            height: '140px',
            width: 'auto',
            margin: '0 auto',
            display: 'block',
          }}
        />
      )}
      <CardContent>
        <Typography variant="h5" component="div">{title}</Typography>
        <Typography variant="body2" color="text.secondary">{description}</Typography>
      </CardContent>
    </HoverCard>
  );
}

export default ProjectCard;
