// src/components/ContactForm.js
import React from 'react';
import { Typography, TextField, Button } from '@mui/material';

function ContactForm() {
  return (
    <form action="https://formspree.io/f/mbjezpbz" method="POST"  style={{marginTop: '30px', marginBottom: '50px'}}>
      <Typography variant="h5">Contact Me!</Typography>
      <TextField name="name" label="Name" variant="outlined" fullWidth margin="normal" />
      <TextField name="Contact Info" label="Email/Phone Number" variant="outlined" fullWidth margin="normal" />
      <TextField name="message" label="Message" variant="outlined" fullWidth multiline rows={4} margin="normal" />
      <Button type="submit" variant="contained" color="primary">Send</Button>
    </form>
  );
}

export default ContactForm;
