import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { GitHub, LinkedIn, Description, Menu as MenuIcon } from '@mui/icons-material';

function NavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Ethan Katte
        </Typography>
        {isMobile ? (
          <>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose} component="a" href="https://github.com/EthanKatte">
                <GitHub /> GitHub
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component="a" href="https://www.linkedin.com/in/ethan-katte-261623151/">
                <LinkedIn /> LinkedIn
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component="a" href="Ethan Katte Resume.pdf" download>
                <Description /> Resume Download
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <IconButton color="inherit" href="https://github.com/EthanKatte">
              <GitHub />
            </IconButton>
            <IconButton color="inherit" href="https://www.linkedin.com/in/ethan-katte-261623151/">
              <LinkedIn />
            </IconButton>
            <Button color="inherit" href="Ethan Katte Resume.pdf" download>
              <Description />
              Resume Download
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;