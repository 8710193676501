import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    highlight: {
      main: '#e8f1fa',
    }
  },
  shadows: Array(25).fill('0px 5px 15px rgba(0,0,0,0.2)'), // Adding a custom shadows array
});

export default theme;